//@flow
import type { ProviderList } from "../types";

/** Provider Sorting */
export const getPhysicians = (providers: ProviderList) => {
  return providers.edges.filter(p => p.node.type == "Spine Surgeon");
};

export const getPhysicalMedicineSpecialists = (providers: ProviderList) => {
  return providers.edges.filter(p => p.node.type == "Physiatrist");
};

export const getAdvancedPracticeProviders = (providers: ProviderList) => {
  return providers.edges.filter(
    p =>
      p.node.type == "Nurse Practitioner" ||
      p.node.type == "Physician Assistant"
  );
};

export const getTherapists = (providers: ProviderList) => {
  return providers.edges.filter(p => p.node.type == "Physical Therapist");
};

export const getAllProviders = (providers: ProviderList) => {
  const phys = getPhysicians(providers);
  const physical = getPhysicalMedicineSpecialists(providers);
  const app = getAdvancedPracticeProviders(providers);
  const therapists = getTherapists(providers);
  return [...phys, ...physical, ...app, ...therapists];
};
