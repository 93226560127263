//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="62"
    viewBox="0 0 62 62"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(6742 6638)">
      <clipPath id="a55" clipRule="evenodd">
        <path fill="#FFF" d="M-6742-6638h62v62h-62v-62z" />
      </clipPath>
      <g clipPath="url(#a55)">
        <path
          fill="#BEB0F4"
          transform="translate(-6740 -6628)"
          id="b"
          fillRule="evenodd"
          d="M57.085 31.982l-.743 2.89c-7.27-1.868-13.818-1.584-19.462.845-3.272 1.408-6.18 3.522-8.64 6.283l-2.23-1.986c7.494-8.404 18.53-11.257 31.075-8.032zm-30.86 1.066l-1.402 2.635c-10.532-5.597-17.935-1.31-18.245-1.125l-1.55-2.552c.367-.223 9.097-5.39 21.196 1.042zM14.126 22.16c3.235.306 6.384 1.207 9.719 2.162 4.693 1.343 9.714 2.78 15.72 2.78 5.402 0 11.6-1.163 19.07-4.602l-1.25-2.71c-5.13 2.362-9.58 3.587-13.574 4.076C36.1 20.422 32.452 13.42 31.17 10.323c.1-.01.204-.022.307-.028 6.442-.41 12.387 6.403 15.358 10.5l2.416-1.75c-3.33-4.596-10.09-12.23-17.963-11.73-.97.063-1.892.254-2.78.532.206-1.127.12-2.302-.272-3.418-1.043-2.97-4.062-4.805-7.18-4.365-1.99.282-3.728 1.423-4.767 3.132-1.04 1.71-1.252 3.778-.586 5.675.582 1.655 1.778 2.956 3.26 3.708-4.01 1.416-7.84.67-10.78-2.185-1.72-1.671-2.84-4-3.07-6.388l-2.97.286c.295 3.082 1.74 6.086 3.96 8.243 3.197 3.104 7.3 4.283 11.574 3.474.475 1.05 1.23 2.51 2.345 4.18-1.847-.455-3.695-.82-5.61-1-5.192-.492-10.04.245-14.41 2.192l1.214 2.726c3.896-1.734 8.242-2.39 12.913-1.948z"
        />
        <path
          fill="#7356B6"
          transform="translate(-6734.97 -6598.17)"
          id="c"
          fillRule="evenodd"
          d="M52.056 2.15l-.743 2.89c-7.27-1.87-13.818-1.585-19.462.844-3.27 1.408-6.178 3.522-8.64 6.283l-2.228-1.986C28.475 1.778 39.51-1.074 52.056 2.15zm-30.86 1.065L19.793 5.85C9.262.253 1.86 4.54 1.55 4.725L0 2.173c.366-.223 9.096-5.39 21.195 1.042z"
        />
        <path
          fill="#7356B6"
          transform="translate(-6740 -6608.96)"
          id="d"
          d="M14.127 3.12c3.234.305 6.383 1.206 9.716 2.16h.003l.004.002c4.692 1.343 9.712 2.78 15.717 2.78 5.4 0 11.6-1.164 19.068-4.604l-1.25-2.71c-5.13 2.363-9.58 3.588-13.573 4.077-5.914.663-10.666.356-23.79-3.678-1.85-.453-3.697-.82-5.613-1C9.216-.343 4.37.394-.002 2.34l1.214 2.727C5.11 3.332 9.456 2.677 14.127 3.12z"
        />
      </g>
    </g>
  </svg>
);
