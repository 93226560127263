// @flow
import { graphql } from "gatsby";
import { default as Image, default as Img } from "gatsby-image";
// @flow
import React from "react";
import styled from "styled-components";
import ArrowLink from "../components/ArrowLink";
import BioCard from "../components/BioCard";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import ImageCard from "../components/ImageCard";
import Page from "../components/Page";
import Section from "../components/Section";
import CheckMark from "../icons/Check";
import FacilitiesIcon from "../icons/Facilities";
import Hospital from "../icons/Hospital";
import PainIcon from "../icons/Pain";
import PT from "../icons/PT";
import DifferentIcon from "../icons/Rocket";
import SolutionsIcon from "../icons/Solutions";
import TeamIcon from "../icons/Team";
import WhatWeTreatIcon from "../icons/WhatWeTreat";
import { MEDIUM_BREAKPOINT, query } from "../style";
import { getAllProviders } from "../utils/functions";
import type { ImageType, ImageSharp, Facility, Provider } from "../types";

const SOLUTIONS_BREAKPOINT = 770;

type Props = {
  data: {
    allContentfulFacility: {
      edges: Array<{ node: Facility }>
    },
    allContentfulProvider: {
      edges: Array<{ node: Provider }>
    },
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    docImg: ImageType,
    treatmentsImg: ImageType,
    surveyVitals: ImageType
  }
};

export default class Index extends React.Component<Props> {
  render() {
    const facilities = this.props.data.allContentfulFacility.edges;
    const providers = this.props.data.allContentfulProvider;
    const {
      mainImage,
      secondImage,
      thirdImage,
      docImg,
      treatmentsImg,
      surveyVitals
    } = this.props.data;
    return (
      <Page
        style={{ overflowX: "hidden" }}
        title="Let's get you back into life"
      >
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Let's get you back into life"
          metaTitle={false}
          subtitle="At the Spine Institue of Idaho, our patients come first. Every year we help thousands of people manage their pain so they can get back to doing what they love."
          cta="Make an appointment"
        />
        <SolutionsSection skewed={false}>
          <SectionHeader>
            <StyledSolutionsIcon />
            <SectionTitle>
              The right solution for <strong>you</strong>
            </SectionTitle>
          </SectionHeader>
          <SolutionsDescription>
            Our focus is to provide a proper diagnosis and treatment plan for
            each individual we see. We believe in comprehensive care, not only
            surgical treatments, because developing healthier patients is at the
            heart of our practice.
          </SolutionsDescription>

          <Solutions>
            <Solution>
              <Hospital />
              <SolutionTitle>Surgical Procedures</SolutionTitle>
              <p>
                Our surgeons are fellowship trained and use cutting edge
                technology to offer a full spectrum of spine surgery. We have
                specialists in everything from minimally invasive procedures to
                complex deformity correction, and everything in between.
              </p>
            </Solution>
            <Solution>
              <PainIcon />
              <SolutionTitle>Pain Management</SolutionTitle>
              <p>
                With the right pain management strategy, many patients find
                their issues resolved with no need for surgery. We are experts
                in non-surgical solutions including injection therapy, spinal
                cord stimulation, medication management, acupressure, manual
                manipulation , and Botox injection therapy.
              </p>
            </Solution>

            <Solution>
              <PT />
              <SolutionTitle>Physical Therapy</SolutionTitle>
              <p>
                Strengthening and stretching the muscles and ligaments that
                protect your spine is an important part of any treatment plan.
                Physiotherapy leads you through effective methods for functional
                restoration so you can get back into life.
              </p>
            </Solution>
          </Solutions>

          <ArrowLink style={{ marginTop: 50 }} to="/services">
            Learn about our services
          </ArrowLink>
        </SolutionsSection>
        {/* can make it #d0f1f9*/}
        <TeamSection color="#EEF2F7">
          <TeamHeader>
            <TeamIcon style={{ marginBottom: 15 }} />
            <TeamTitle>Meet our team</TeamTitle>
            <TeamCopy>
              Our physicians and staff are experienced and professional, but we
              know it’s not just about that. You can trust us to care.
            </TeamCopy>
          </TeamHeader>
          <Carousel>
            {currentIndex =>
              getAllProviders(providers).map((phys, i) => (
                <BioCard
                  style={{
                    opacity: currentIndex == i ? 1 : 0.5,
                    transform: currentIndex == i ? "scale(1)" : "scale(0.9)"
                  }}
                  isActive={currentIndex == i}
                  key={phys.node.name}
                  name={phys.node.name}
                  subheading={phys.node.type}
                  image={phys.node.headshot}
                  description={phys.node.bioSummary}
                  to={`/team/${phys.node.name.toLowerCase().replace(" ", "-")}`}
                />
              ))
            }
          </Carousel>
          <ArrowLink to="/team" style={{ marginTop: 20 }}>
            Meet our team
          </ArrowLink>
        </TeamSection>

        <TestimonyRow>
          <SurveyVitals href="https://surveyvitals.com/images/SurveyVitals-Top10-Quarter-four-2017.png">
            <Image
              sizes={surveyVitals.childImageSharp.sizes}
              width="100%"
              alt="SurveyVitals Patient Experience Top10"
            />
          </SurveyVitals>
          <TestimoniesSection
            style={{ flex: 1 }}
            skewed={false}
            color="#E3E0F3"
            one
          >
            <Testimony>
              I am confident I am working with highly competent and caring
              people who are not afraid to motivate, guide and inspire me. And I
              am deeply grateful for the recommendation that I engage in
              physical therapy at Spine Institute of Idaho.
            </Testimony>
            <Author>
              - Privacy Protected per <br />
              HIPAA regulations
              <br />
              <br />
              July, 2017
            </Author>
          </TestimoniesSection>

          <TestimoniesSection
            style={{ flex: 1 }}
            skewed={false}
            color="#8b8dc0"
            inverse
          >
            <Testimony>
              Thank you all for taking good care of me, and making a scary,
              painful situation so wonderful. The doctors and staff are awesome,
              brilliant, and merciful! God bless you all!
            </Testimony>
            <Author>
              - Privacy Protected per <br />
              HIPAA regulations
              <br />
              <br />
              September, 2017
            </Author>
          </TestimoniesSection>
        </TestimonyRow>

        <TestimonyRow>
          <TestimoniesSection
            style={{ flex: 1 }}
            skewed={false}
            color="#645f8d"
            inverse
          >
            <Testimony>
              I was highly impressed by how friendly and knowledgeable the
              entire staff was. From check in to check out, it was an easy and
              pleasant process.
            </Testimony>
            <Author>
              - Privacy Protected per <br />
              HIPAA regulations
              <br />
              <br />
              February, 2017
            </Author>
          </TestimoniesSection>

          <TestimoniesSection
            style={{ flex: 1 }}
            skewed={false}
            color="#c1c3eb"
          >
            <Testimony>
              I wouldn’t go to another spine Dr for anything. I like the fact
              that they take good care of me & they listen to me about any
              questions I have or if I have any concerns, they address them in
              the office when I am there.
            </Testimony>
            <Author>
              - Privacy Protected per <br />
              HIPAA regulations
              <br />
              <br /> November, 2017
            </Author>
          </TestimoniesSection>
        </TestimonyRow>

        <MakesUsDifferent>
          <DifferentSectionHeader>
            <StyledDifferentIcon />
            <SectionTitle style={{ fontSize: "1.8rem" }}>
              What makes us different
            </SectionTitle>
            <SectionHead>
              <strong>
                The Spine Institute of Idaho is a single point destination.
              </strong>{" "}
              We offer everything from surgery to rehab to pain management and
              everything in between, all coordinated in one place by a
              cooperative team of specialists.
            </SectionHead>
          </DifferentSectionHeader>
          <DifferentImage sizes={docImg.childImageSharp.sizes} />
          <Checks>
            <Check
              body="It dramatically improves outcomes and satisfaction, so we became industry leaders in the technique."
              leader="Specialists in minimally invasive surgery."
            />
            <Check
              odd
              leader="Surgical and nonsurgical options."
              body="When surgery is the right option, we are the best in town. When surgery isn’t the right option, we are still the best in town."
            />
            <Check
              leader="Leaders in scoliosis correction."
              body="Our providers often participate and present at conferences across the nation on our work developing new corrective implants and devices."
            />
            <Check
              odd
              leader="On site injection suite and physical therapy. "
              body="Don’t spend your time running around town from doctor to doctor, spend it getting better."
            />
            <Check
              leader="Leading edge imaging technology. "
              body="The right diagnosis is paramount, which is why we use a sophisticated Open MRI, X-Ray, and a highly trained imaging staff."
            />
            <Check
              odd
              leader="Always improving."
              body="For us, learning doesn’t stop after medical school. Everyone on the team sets aside time to learn new techniques and contribute back to our fields."
            />
          </Checks>
        </MakesUsDifferent>

        <WhatWeTreat color="#d0e5ff">
          <DifferentSectionHeader>
            <WhatWeTreatIcon />
            <SectionTitle>See what we treat</SectionTitle>
          </DifferentSectionHeader>
          <TreatmentWrap>
            <TreatmentsImage image={treatmentsImg.childImageSharp} />
            <TreatWrap style={{ flex: 1, margin: 20 }}>
              <TreatmentBody>
                From complex spinal curvatures to disc and degeneration issues,
                our Surgical Team, Physiatrists, Pain Physicians and Physical
                Therapists are prepared to help you find a lasting solution.
                With deep expertise in both surgical and nonsurgical options, we
                will exhaust every avenue to make sure you’re confident in your
                treatment plan.
              </TreatmentBody>
              <ArrowLink to="/conditions">See conditions we treat</ArrowLink>
            </TreatWrap>
          </TreatmentWrap>
        </WhatWeTreat>

        <FacilitiesSection skewed={false} noBackground>
          <DifferentSectionHeader>
            <FacilitiesIcon />
            <SectionTitle style={{ fontSize: "1.8rem" }}>
              Explore the facilities
            </SectionTitle>
            <TeamCopy style={{ textAlign: "left" }}>
              It is our goal to provide the best possible experience, and we
              didn’t stop at assembling a top team. We designed a
              multidisciplinary facility adjacent to St Luke's Meridian Hospital
              and an additional office in Caldwell adjacent to West Valley
              Hospital. Our onsite range of facilities include a Full-service
              Injection Suite, Open MRI, X-Ray, and an Expansive Physical
              Therapy Clinic.
            </TeamCopy>
          </DifferentSectionHeader>
          <Carousel>
            {currentIndex =>
              facilities.map(({ node: fac }, i) => (
                <BioCard
                  style={{
                    opacity: currentIndex == i ? 1 : 0.5,
                    transform: currentIndex == i ? "scale(1)" : "scale(0.9)"
                  }}
                  isActive={currentIndex == i}
                  to="/facilities"
                  key={fac.name}
                  image={fac.headerImage}
                  description={fac.shortDescription}
                  name={fac.name}
                />
              ))
            }
          </Carousel>
          <ArrowLink to="/facilities">Explore our facilities</ArrowLink>
        </FacilitiesSection>
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const SurveyVitals = styled.a`
  && {
    position: absolute;
    left: calc(50% - 100px);
    top: -150px;
    z-index: 1000;
    /* transform: skewY(12deg); */
    width: 200px;
  }
`;
const SectionHead = styled.p`
  && {
    margin: 1rem auto 3rem auto;
    max-width: 800px;
    font-size: 18px;
  }
`;

const FacilitiesSection = styled(Section)`
  && {
  }
`;

const TREAT_BREAKPOINT = 925;

const TreatWrap = styled.div`
  && {
    flex: 1;
    margin: auto;
    max-width: 490px;
  }
`;

const TreatmentWrap = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
`;

const TreatmentBody = styled.p`
  && {
    flex: 1;
  }
`;

const TreatmentsImage = styled(ImageCard)`
  && {
    display: none;
    flex: 1;
    ${query(TREAT_BREAKPOINT)`
    display: block;
  `};
  }
`;

const WhatWeTreat = styled(Section)`
  && {
  }
`;

const CHECK_BREAKPOINT = 735;

const Check = ({
  body,
  leader,
  odd
}: {
  body: string,
  leader: string,
  odd?: boolean
}) => (
  <Item>
    <CheckMark odd={odd} style={{ width: 77 }} />
    <Body>
      <strong>{leader + " "}</strong>
      {body}
    </Body>
  </Item>
);

const Item = styled.li`
  && {
    display: flex;
    margin-top: 25px;
    ${query(CHECK_BREAKPOINT)`
    max-width: 45%;
  `};
  }
`;

const Body = styled.p`
  && {
    margin-left: 10px;
  }
`;

const Checks = styled.ul`
  && {
    margin-top: 60px;
    ${query(CHECK_BREAKPOINT)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  `};
  }
`;

const DifferentImage = styled(Img)`
  && {
    width: 100%;
    height: auto;
    max-width: 700px;
    margin: auto;
    display: block;
  }
`;

const DifferentSectionHeader = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const StyledDifferentIcon = styled(DifferentIcon)`
  && {
  }
`;

const MakesUsDifferent = styled(Section)`
  && {
  }
`;

const Testimony = styled.p`
  && {
    max-width: 400px;
    margin: auto;
    text-align: center;
  }
`;

const TestimonyRow = styled.div`
  && {
    /* transform: skewY(-12deg); */
    position: relative;

    ${query(720)`
    display: flex;
    flex-direction: row;
  `};
  }
`;

const Author = styled.div`
  && {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;
    line-height: 1;
  }
`;

const TestimoniesSection = styled(Section)`
  && {
    /* transform: skewY(12deg); */

    text-align: center;
    font-style: italic;
    font-size: 20px;

    ${props => props.inverse && "color: white;"};
  }
`;

const TeamCopy = styled.p`
  && {
    max-width: 580px;
    margin: auto;
  }
`;

const TeamTitle = styled.h1`
  && {
    text-align: center;
    margin-top: 0;
  }
`;

const TeamHeader = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TeamSection = styled(Section)`
  && {
    padding: 60px 20px 180px;
  }
`;

const SolutionTitle = styled.h4`
  && {
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const Solution = styled.div`
  && {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    ${query(SOLUTIONS_BREAKPOINT)`
    margin-right: 10px;
  `};
  }
`;

const Solutions = styled.div`
  && {
    ${query(SOLUTIONS_BREAKPOINT)`
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: flex-start;
  `};
  }
`;

const SectionTitle = styled.h1`
  && {
    margin-top: 30px;
  }
`;

const SectionHeader = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${query(MEDIUM_BREAKPOINT)`
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 30px;
  `};
  }
`;

const SolutionsDescription = styled.div`
  && {
    margin-bottom: 30px;
    ${query(MEDIUM_BREAKPOINT)`
    max-width: 600px;
    margin-bottom: 60px;
  `};
  }
`;

const StyledSolutionsIcon = styled(SolutionsIcon)`
  && {
    ${query(MEDIUM_BREAKPOINT)`
    margin-right: 20px;
  `};
  }
`;

const SolutionsSection = styled(Section)`
  && {
    padding-top: 150px !important;
  }
`;

export const pageQuery = graphql`
  query Index {
    allContentfulProvider(sort: { fields: order }) {
      edges {
        node {
          name
          type
          order
          bioSummary
          headshot {
            sizes(maxWidth: 350) {
              ...GatsbyContentfulSizes
            }
            resolutions(width: 335) {
              ...GatsbyContentfulResolutions
            }
          }
        }
      }
    }
    allContentfulFacility(filter: { displayOnHomePage: { eq: true } }) {
      edges {
        node {
          shortDescription
          name
          headerImage {
            sizes(maxWidth: 350) {
              ...GatsbyContentfulSizes
            }
            resolutions(width: 335) {
              ...GatsbyContentfulResolutions
            }
          }
        }
      }
    }
    surveyVitals: file(relativePath: { eq: "home/SurveyVitals.png" }) {
      childImageSharp {
        sizes(maxWidth: 200) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    mainImage: file(relativePath: { eq: "square/surgery-2.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/pt.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/MRI.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    docImg: file(relativePath: { eq: "home/nurse.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    treatmentsImg: file(relativePath: { eq: "home/treatments.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;
