//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="#C4F0FF"
      d="M36 0c19.882 0 36 16.117 36 36 0 19.882-16.118 36-36 36S0 55.882 0 36C0 16.116 16.118 0 36 0z"
    />
    <path
      fill="#87BBFD"
      transform="translate(17 29)"
      d="M1.5 25c-.398 0-.78-.158-1.06-.44C.157 24.28 0 23.9 0 23.5v-22C0 1.102.158.72.44.44.72.157 1.1 0 1.5 0H20v25H1.5zM8 4c0-.265-.105-.52-.293-.707C7.52 3.105 7.265 3 7 3H4c-.265 0-.52.105-.707.293C3.105 3.48 3 3.735 3 4v3c0 .265.105.52.293.707C3.48 7.895 3.735 8 4 8h3c.265 0 .52-.105.707-.293C7.895 7.52 8 7.265 8 7V4zm0 8c0-.265-.105-.52-.293-.707C7.52 11.105 7.265 11 7 11H4c-.265 0-.52.105-.707.293C3.105 11.48 3 11.735 3 12v3c0 .265.105.52.293.707.187.188.442.293.707.293h3c.265 0 .52-.105.707-.293C7.895 15.52 8 15.265 8 15v-3zm7-8c0-.265-.105-.52-.293-.707C14.52 3.105 14.265 3 14 3h-3c-.265 0-.52.105-.707.293C10.105 3.48 10 3.735 10 4v3c0 .265.105.52.293.707.187.188.442.293.707.293h3c.265 0 .52-.105.707-.293C14.895 7.52 15 7.265 15 7V4z"
    />
    <path
      fill="#6772E5"
      transform="translate(35 16)"
      d="M16.5 38H12v-8c0-.265-.105-.52-.293-.707C11.52 29.105 11.265 29 11 29H7c-.265 0-.52.105-.707.293C6.105 29.48 6 29.735 6 30v8H0V1.5C0 1.102.158.72.44.44.72.157 1.1 0 1.5 0h15c.398 0 .78.158 1.06.44.282.28.44.662.44 1.06v35c0 .398-.158.78-.44 1.06-.28.282-.662.44-1.06.44zM8 13c0-.265-.105-.52-.293-.707C7.52 12.105 7.265 12 7 12H4c-.265 0-.52.105-.707.293C3.105 12.48 3 12.735 3 13v3c0 .265.105.52.293.707.187.188.442.293.707.293h3c.265 0 .52-.105.707-.293C7.895 16.52 8 16.265 8 16v-3zm0 8c0-.265-.105-.52-.293-.707C7.52 20.105 7.265 20 7 20H4c-.265 0-.52.105-.707.293C3.105 20.48 3 20.735 3 21v3c0 .265.105.52.293.707.187.188.442.293.707.293h3c.265 0 .52-.105.707-.293C7.895 24.52 8 24.265 8 24v-3zm7 0c0-.265-.105-.52-.293-.707C14.52 20.105 14.265 20 14 20h-3c-.265 0-.52.105-.707.293-.188.187-.293.442-.293.707v3c0 .265.105.52.293.707.187.188.442.293.707.293h3c.265 0 .52-.105.707-.293.188-.187.293-.442.293-.707v-3z"
    />
    <path
      fill="#87BBFD"
      transform="translate(38 20)"
      d="M11 13H8c-.265 0-.52-.105-.707-.293C7.105 12.52 7 12.265 7 12V9c0-.265.105-.52.293-.707C7.48 8.105 7.735 8 8 8h3c.265 0 .52.105.707.293.188.187.293.442.293.707v3c0 .265-.105.52-.293.707-.187.188-.442.293-.707.293zm0-8H8c-.265 0-.52-.105-.707-.293C7.105 4.52 7 4.265 7 4V1c0-.265.105-.52.293-.707C7.48.105 7.735 0 8 0h3c.265 0 .52.105.707.293C11.895.48 12 .735 12 1v3c0 .265-.105.52-.293.707C11.52 4.895 11.265 5 11 5zM4 5H1c-.265 0-.52-.105-.707-.293C.105 4.52 0 4.265 0 4V1C0 .735.105.48.293.293.48.105.735 0 1 0h3c.265 0 .52.105.707.293C4.895.48 5 .735 5 1v3c0 .265-.105.52-.293.707C4.52 4.895 4.265 5 4 5zM2 32h8c.265 0 .52.105.707.293.188.187.293.442.293.707v1H1v-1c0-.265.105-.52.293-.707C1.48 32.105 1.735 32 2 32z"
    />
  </svg>
);
