//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="#D2F2FD"
      d="M36 72c19.882 0 36-16.118 36-36S55.882 0 36 0 0 16.118 0 36s16.118 36 36 36z"
    />
    <g fill="#BEB0F4" transform="translate(18 18)">
      <path
        fillRule="evenodd"
        d="M4.097 20.454c1.02-.258 2.09-.53 3.04-.776 1.17-2.865 2.923-5.613 5.145-8.303l-1.696-.35c-1.695-.352-3.45.35-4.444 1.753-2.34 3.275-6.256 8.888-6.14 8.77.08-.077 1.973-.557 4.095-1.094zm11.167 7.352c2.924-1.17 5.73-2.865 8.362-5.087l.35 1.636c.352 1.696-.35 3.45-1.753 4.444-1.55 1.06-3.59 2.474-5.32 3.67-1.993 1.38-3.572 2.474-3.51 2.412.05-.05.777-2.858 1.458-5.482l.414-1.594zm10.7-26.02C31.287-.38 35.03.03 35.03.03s.467 3.685-1.755 9.006l-7.31-7.25zm7.018 7.952l-7.66-7.66c-3.04 1.344-6.55 3.508-10.116 7.075-4.093 4.093-6.374 8.01-7.66 11.286l6.958 6.957c3.333-1.287 7.25-3.567 11.344-7.602 3.567-3.567 5.79-7.017 7.134-10.057zm-12.63-1.755c1.87-1.812 4.853-1.812 6.666 0 1.87 1.872 1.87 4.795 0 6.608-1.87 1.814-4.854 1.814-6.666 0-1.813-1.812-1.813-4.794 0-6.607z"
      />
      <path d="M10.294 24.707c-1.52-1.52-4.386-3.625-7.543.06C.705 27.162.237 31.138.237 31.138s1.053-2.106 2.28-2.75C3.747 27.75.47 35.117.53 35c.057-.118 6.022-4.562 6.49-3.51.467 1.053-1.99 3.1-1.99 3.1s4.27-1.23 5.79-3.1c1.58-1.87 2.398-3.918-.526-6.783z" />
    </g>
    <path
      transform="translate(18.24 40.71)"
      fill="#7356B6"
      d="M10.058 1.993c-1.52-1.52-4.386-3.626-7.544.058C.468 4.45 0 8.426 0 8.426S1.053 6.32 2.28 5.677C3.51 5.033.235 12.4.293 12.284c.06-.117 6.023-4.56 6.49-3.508.47 1.052-1.987 3.1-1.987 3.1s4.27-1.23 5.79-3.1c1.578-1.872 2.396-3.918-.527-6.783z"
    />
  </svg>
);
