// @flow
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { blue } from '../style/colors';
import Button from './button';
import type { ImageSharp } from '../types';
import Img from 'gatsby-image';

export default ({
  name = 'Samuel Jorgenson',
  subheading,
  image,
  className,
  description = " Dr. Jorgenson's current research includes minimally invasive spine surgery and scoliosis implants.",
  src = '',
  to = '/team',
  isActive = false,
  style = {},
}: {
  name?: string,
  image?: ImageSharp,
  src?: string,
  description?: string,
  className?: string,
  subheading?: string,
  to?: string,
  isActive?: boolean,
  style?: any,
}) => {
  return (
    <TransitionCard
      style={{ margin: 20, maxWidth: 335, width: '90vw', ...style }}
      className={className}
      raised={isActive}
    >
      {image && image.sizes ? (
        <CardMedia
          sizes={image && image.sizes}
          // rfesolutions={image && image.resolutions}
        />
      ) : (
        <CardMediaOrig
          src={image ? image.resolutions.base64 : src}
          srcSet={image ? image.resolutions.srcSet : ''}
          style={{ width: '100%' }}
        />
      )}
      <CardContent>
        <Name>{name}</Name>
        {subheading && (
          <Subheading variant="subheading">{subheading}</Subheading>
        )}
        <Research>{description}</Research>
      </CardContent>
      <CardActions>
        <Link to={to}>
          <Button flat size="small">
            Learn More
          </Button>
        </Link>
      </CardActions>
    </TransitionCard>
  );
};

const TransitionCard = styled(Card)`
  && {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }
`;

const CardMedia = styled(Img)`
  && {
    width: 100%;
  }
`;
const CardMediaOrig = styled.img`
  && {
  }
`;

const Name = styled.h3`
  && {
    margin-top: 14px;
    margin-bottom: 5px;
  }
`;

const Subheading = styled(Typography)`
  && {
    color: ${blue[300]};
    font-size: 15px;
    font-weight: 300;
  }
`;

const Research = styled.p`
  && {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;
