//@flow
import React from 'react';

export default ({ className, style }: { className?: string, style?: any }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="62"
    height="62"
    viewBox="0 0 62 62"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(6742 6846)">
      <clipPath id="a" clipRule="evenodd">
        <path fill="#FFF" d="M-6742-6846h62v62h-62v-62z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          fill="#BEB0F4"
          transform="translate(-6732 -6838)"
          fillRule="evenodd"
          d="M6.934 21.793l.928 2.783-6.934 2.31L0 24.107l6.934-2.313zM1.6 11.763L9.444 14.7l-1.027 2.747-7.846-2.933L1.6 11.764zm11.193-.28L7.327 4.727l2.28-1.845 5.467 6.755-2.28 1.846zM42.62 22.886c-3.234-7.672-7.442-10.75-10.585-11.966 1.24-1.368 1.843-3.19 1.66-5.048-.306-3.08-2.762-5.534-5.84-5.84-1.967-.194-3.892.49-5.283 1.88-1.39 1.392-2.075 3.317-1.88 5.283.144 1.46.776 2.776 1.725 3.793-2.332.76-4.332 2.117-5.972 4.07C8.425 24.6 11.9 45.13 12.052 46h2.89c-.01-.05-.087-19.934 3.78-18.957 3.868.978 5.248 18.88 5.255 18.957H26.9c-.904-11.18-.412-27.663 3.262-31.275.307.374.63.82.946 1.352 1.386 2.326 2.91 6.676 2.3 14.19l2.923.237c.583-7.152-.62-11.88-2.167-14.945 1.954 1.63 3.982 4.262 5.755 8.466l2.703-1.14z"
        />
        <g fill="#7356B6" transform="translate(-6732 -6835.12)">
          <path
            id="c"
            d="M7.862 21.695l-.928-2.784L0 21.224l.928 2.783 6.934-2.31z"
          />
          <path
            id="d"
            d="M9.445 11.816L1.6 8.882.57 11.63l7.846 2.934 1.027-2.748z"
          />
          <path
            id="e"
            d="M7.327 1.846L12.793 8.6l2.28-1.845L9.608 0l-2.28 1.846z"
          />
        </g>
      </g>
    </g>
  </svg>
);
