//@flow
import React from 'react';
import { purple } from '../style/colors';

export default ({
  className,
  style,
  ...rest
}: {
  className?: string,
  style?: any,
  rest?: $Rest<any, any>,
}) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="72"
    height="72"
  >
    <use fill={purple[500]} xlinkHref="#path0_fill_team" />
    <use
      fill="#d2f2fd"
      transform="translate(19.5 25.29)"
      xlinkHref="#path1_fill_team"
    />
    <use
      fill="#d2f2fd"
      transform="translate(37 25.29)"
      xlinkHref="#path2_fill_team"
    />
    <defs>
      <path
        id="path0_fill_team"
        d="M36 72a36 36 0 1 0 0-72 36 36 0 0 0 0 72z"
      />
      <path
        id="path1_fill_team"
        d="M21.88 8.75a4.36 4.36 0 0 0 4.36-4.38 4.37 4.37 0 1 0-4.36 4.38zm-11.67 0a4.36 4.36 0 0 0 4.36-4.38 4.37 4.37 0 1 0-4.36 4.38zm0 2.92C6.8 11.67 0 13.37 0 16.77v3.65h20.42v-3.65c0-3.4-6.81-5.1-10.21-5.1zm11.66 0c-.42 0-.9.03-1.41.07 1.7 1.22 2.87 2.87 2.87 5.03v3.65h8.75v-3.65c0-3.4-6.8-5.1-10.2-5.1z"
      />
      <path
        id="path2_fill_team"
        d="M4.38 8.75a4.36 4.36 0 0 0 4.36-4.38 4.37 4.37 0 1 0-4.36 4.38zm0 2.92c-.43 0-.9.03-1.42.07 1.7 1.22 2.87 2.87 2.87 5.03v3.65h8.75v-3.65c0-3.4-6.8-5.1-10.2-5.1z"
      />
    </defs>
  </svg>
);
